import SettingsAllgemein from "./SettingsAllgemein";
import {Alert, Button, ListItemText, MenuItem, Snackbar, ThemeProvider} from "@mui/material";
import {CustomFontTheme} from "../x01/CustomFontTheme";
import {NAVIGATION_ITEM} from "../constants/navigationItems";
import React, {useEffect, useRef, useState} from "react";
import SettingsAnzeige from "./SettingsAnzeige";
import SettingsDHVerbindung from "./SettingsDHVerbindung";
import {isDesktop, isSmartphone} from "../util/GeraeteArtUtil";
import SettingsShortcuts from "./SettingsShortcuts";
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsAdmin from "./SettingsAdmin";

function Settings() {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [entwicklungsCounter, setEntwicklungsCounter] = useState(0);

    const [tabValue, setTabValue] = useState("allgemein");
    const [showMenu, setShowMenu] = useState(false);

    const menu = useRef(null);

    useEffect(() => {
        window.addEventListener("keydown", onKeyDown);
        return () => {
            window.removeEventListener("keydown", onKeyDown);
        }
    }, []);

    useEffect(() => {
        if( entwicklungsCounter > 10) {
            setInfoMessage("Entwicklermodus wurde aktiviert.");
        }
    }, [entwicklungsCounter]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onKeyDown = (event) => {
        switch (event.key) {
            case "Escape":
                window.open(NAVIGATION_ITEM.DASHBOARD.route, "_self");
                event.preventDefault();
                break;
            case "F1":
            case "F2":
            case "F3":
            case "F4":
            case "F6":
            case "F7":
            case "F8":
            case "F9":
            case "F10":
            case "F11":
            case "F12":
                // F Tasten werden damit abgeschalten. F5 für Refresh wird zum Browser durchgelassen
                event.preventDefault();
                break;
            default:
                break;
        }
        event.stopPropagation();
    };

    const version = process.env.REACT_APP_VERSION;

    const erzeugeTab = (value, key) => {
        return <div style={{fontSize: 20, width:200, marginLeft:5, cursor:"pointer", borderBottom: tabValue === key ? "2px solid red" : "none", textAlign:"center"}}
                    onClick={() => setTabValue(key)}>
            {value.toUpperCase()}
        </div>
    }

    const getTabs = () => {
        let returnValue = [];
        returnValue.push(erzeugeTab("Allgemein", "allgemein"));
        returnValue.push(erzeugeTab("DH-Verbindung", "dhverbindung"));
        returnValue.push(erzeugeTab("Anzeige", "anzeige"));
        returnValue.push(erzeugeTab("Shortcuts", "shortcut"));
        if( entwicklungsCounter > 10) {
            returnValue.push(erzeugeTab("Admin", "admin"));
        }
        return returnValue;
    }

    const getMenuItems = () => {
        let returnValue = [];
        returnValue.push(<MenuItem style={{marginBottom:5}}><ListItemText onClick={() => {
            setTabValue("allgemein");
            handleClose();
        }} primaryTypographyProps={{fontSize: 20}} style={{marginRight:10, borderLeft: tabValue === "allgemein" ? "10px solid #ac1c27" : "none"}}>Allgemein</ListItemText></MenuItem>);
        returnValue.push(<MenuItem style={{marginBottom:5}}><ListItemText onClick={() => {
            setTabValue("dhverbindung");
            handleClose();
        }} primaryTypographyProps={{fontSize: 20}} style={{borderLeft: tabValue === "dhverbindung" ? "10px solid #ac1c27" : "none"}}>DH-Verbindung</ListItemText></MenuItem>);
        returnValue.push(<MenuItem style={{marginBottom:5}}><ListItemText onClick={() => {
            setTabValue("anzeige");
            handleClose();
        }} primaryTypographyProps={{fontSize: 20}} style={{borderLeft: tabValue === "anzeige" ? "10px solid #ac1c27" : "none"}}>Anzeige</ListItemText></MenuItem>);
        returnValue.push(<MenuItem style={{marginBottom:5}}><ListItemText onClick={() => {
            setTabValue("shortcut");
            handleClose();
        }} primaryTypographyProps={{fontSize: 20}} style={{borderLeft: tabValue === "shortcut" ? "10px solid #ac1c27" : "none"}}>Shortcuts</ListItemText></MenuItem>);

        if( entwicklungsCounter > 10) {
            returnValue.push(<MenuItem style={{marginBottom:5}}><ListItemText onClick={() => {
                setTabValue("admin");
                handleClose();
            }} primaryTypographyProps={{fontSize: 20}} style={{borderLeft: tabValue === "admin" ? "10px solid #ac1c27" : "none"}}>Admin</ListItemText></MenuItem>);
        }

        return returnValue;
    }

    const getTabpanel = () => {
        if( tabValue === "allgemein" ) {
            return <div><SettingsAllgemein/></div>
        } else if( tabValue === "dhverbindung" ) {
            return <div><SettingsDHVerbindung/></div>
        } else if( tabValue === "anzeige" ) {
            return <div><SettingsAnzeige/></div>
        } else if( tabValue === "shortcut" ) {
            return <div><SettingsShortcuts/></div>
        } else if( tabValue === "admin" ) {
            return <div><SettingsAdmin/></div>
        }
    }

    return <div style={{padding:20}}>
        <Snackbar open={infoMessage !== undefined} autoHideDuration={3000} onClose={() => setInfoMessage(undefined)}
                  anchorOrigin={{ vertical:"top", horizontal:"center" }}>
            <Alert onClose={() => this.setState({message: undefined})} severity="info" sx={{ width: '100%', fontSize:30 }}>
                {infoMessage}
            </Alert>
        </Snackbar>
        <div style={{position: "fixed", right: 5, top: 5, zIndex:1000}}>
            <ThemeProvider theme={CustomFontTheme}>
                <Button variant="text"
                        onClick={() => window.open(NAVIGATION_ITEM.DASHBOARD.route, "_self")}>Schliessen{isDesktop() && " (Esc)"}</Button>
            </ThemeProvider>
        </div>
        <div style={{marginBottom: 20}} title={version} onClick={() => setEntwicklungsCounter(entwicklungsCounter + 1)}>
            Version: {version}
        </div>
        {isSmartphone() && <>

            <MenuIcon style={{fontSize: 50}} onClick={handleClick}/>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {getMenuItems()}
            </Menu>
        </>}
        {!isSmartphone() && <>
            <div style={{display:"flex"}}>
                {getTabs()}
            </div>
        </>}
        <div style={{marginTop:20}}>
            {getTabpanel()}
        </div>
    </div>
}

export default Settings;
