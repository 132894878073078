import React, {useEffect, useState} from "react";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import {NAVIGATION_ITEM} from "../constants/navigationItems";
import FetchUtil from "../util/FetchUtil";
import {getFromLocalStorage} from "../localStorageZugriff";
import {Alert} from "@mui/material";
import {isSmartphone} from "../util/GeraeteArtUtil";

export default function LiveViewInternetOverview() {

    const [url] = useState(getFromLocalStorage("dh_url"));
    const [token] = useState(getFromLocalStorage("dh_token"));
    const [boardToken] = useState(getFromLocalStorage("dh_board_token"));
    const [configOK] = useState(url !== undefined && url !== "" && token !== undefined && token !== "" && boardToken !== undefined && boardToken !== "");

    const [errorMessage, setErrorMessage] = useState(undefined);
    const [loading, setLoading] = useState(false);

    let [eventOperatorId, setEventOperatorId] = useState(undefined);
    let [boardId, setBoardId] = useState(undefined);
    let [boardUrl, setBoardUrl] = useState(undefined);

    useEffect(() => {
        if( !configOK) {
            return;
        }
        setErrorMessage(undefined);
        setLoading(true);
        FetchUtil.fetchGet(url + "/boardinfo", token, boardToken,
            json => {
                setBoardId(json.boardId);
                setEventOperatorId(json.eventOperatorId);
                let boardUrl = "";
                if( url.includes("localhost") ) {
                    boardUrl = "http://localhost:3000/#/";
                } else if( url.includes("test.") ) {
                    boardUrl = "https://test.darthelfer.de/#/";
                } else {
                    boardUrl = "https://darthelfer.de/#/";
                }
                boardUrl += "public/eventoperator/liveview?eventoperatorid=" + json.eventOperatorId + "&boardid=" + json.boardId;
                setBoardUrl(boardUrl);
                setLoading(false);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message)
                setLoading(false);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        window.addEventListener("keydown", onKeyDown);

        return () => window.removeEventListener("keydown", onKeyDown);
    }, []);

    useEffect(() => {
        window.addEventListener("keydown", onKeyDown);

        return () => window.removeEventListener("keydown", onKeyDown);
    }, []);

    const onKeyDown = (event) => {
        switch (event.key) {
            case "Escape":
            case "*":
                window.open(NAVIGATION_ITEM.DASHBOARD.route, "_self");
                event.preventDefault();
                break;
            case "F1":
            case "F2":
            case "F3":
            case "F4":
            case "F6":
            case "F7":
            case "F8":
            case "F9":
            case "F10":
            case "F12":
                // F Tasten werden damit abgeschalten. F5 für Refresh wird zum Browser durchgelassen
                event.preventDefault();
                break;
            default:
                break;
        }
        event.stopPropagation();
    };

    if( !configOK) {
        return <div style={{backgroundColor: "#022836", minHeight: "100vh", height: "100%"}}>
            <ExitToAppOutlinedIcon style={{fontSize:100, color:"black", position:"absolute", bottom:5, left:5, cursor:"pointer"}} onClick={() => window.open(NAVIGATION_ITEM.DASHBOARD.route, "_self")}/>
            <Alert onClose={() => setErrorMessage(undefined)} severity="error" sx={{width: '100%', fontSize: isSmartphone()? 20 : 30}}>
                Board ist nicht konfiguriert...
            </Alert>
        </div>
    }

    return <div style={{backgroundColor: "#022836", minHeight: "100vh", height: "100%"}}>
        <ExitToAppOutlinedIcon style={{fontSize:100, color:"black", position:"absolute", bottom:5, left:5, cursor:"pointer"}} onClick={() => window.open(NAVIGATION_ITEM.DASHBOARD.route, "_self")}/>
        {errorMessage !== undefined &&
            <Alert onClose={() => setErrorMessage(undefined)} severity="error" sx={{width: '100%', fontSize: isSmartphone()? 20 : 30}}>
                {errorMessage}
            </Alert>
        }
        {configOK && loading && <div style={{fontSize:20, color:"white", marginTop:10}}>Suche das Board...</div>}
        {boardUrl !== undefined &&
            <iframe width="100%" height="10000"
                    src={boardUrl}
                    frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen="true"></iframe>
        }
    </div>
}
